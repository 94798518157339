import { css } from "lit-element";

export const embedStyles = css`
  :host {
    font-family: Arial, Helvetica, sans-serif;
  }
  #root {
    width: 100%;
    display: grid;
  }
  #entry-button {
    width: 100%;
    height: var(--entry-button-height, 100px);
    grid-area: 1 / 1;
    border: none;
  }
  #discover-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    grid-area: 1 / 1;
    background: var(--backdrop, rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--modal-z-index, 10);
  }
  #discover-container {
    border-radius: 8px;
    width: 90%;
    height: 90%;
    background-color: white;
  }
  #appbar-root {
    border-radius: 8px 8px 0 0;
    height: 52px;
    position: relative;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #appbar-container {
    align-items: center;
    display: flex;
    height: 100%;
  }
  #close-button-container {
    height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  #logo-container {
    height: 48px;
    flex: 1;
    text-align: center;
  }
  #logo {
    background-image: var(
      --logo,
      url("https://static.vouchrsdk.com/assets/images/vouchr_logo.svg")
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
    height: 100%;
    background-size: contain;
    margin: 0 auto;
    max-width: 100px;
  }
  #appbar-placeholder {
    flex: 1;
  }
  #discover {
    border-radius: 0 0 8px 8px;
    width: 100%;
    height: calc(100% - 52px);
    border: none;
  }
  #send-button {
    height: 50px;
    width: 100%;
  }
  #share-sheet-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    width: 100vw;
    grid-area: 1 / 1;
    background: var(--backdrop, rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--modal-z-index, 10);
  }
  #share-sheet {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 250px;
    background-color: white;
    justify-content: center;
    align-items: center;
  }
  #share-sheet-header,
  #share-btn-container {
    margin-top: 4px;
    width: 80%;
  }
  #share-sheet-header-title {
    font-weight: bold;
    float: left;
  }
  #share-sheet-close-btn {
    float: right;
  }
  #copy-button,
  #email-button,
  #share-button,
  #print-button {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .btn-primary,
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:visited,
  .btn-primary:focus {
    background-color: var(--primary, #0066ff) !important;
    border-color: var(--primary, #0066ff) !important;
    color: var(--solidButtonTextColor) !important;
  }
  .btn-outline-primary,
  .btn-outline-primary:active,
  .btn-outline-primary:visited,
  .btn-outline-primary:focus {
    border-color: var(--primary, #0066ff) !important;
    color: black !important;
  }
  .btn-outline-primary:hover {
    opacity: 0.85;
    border-color: var(--primary, #0066ff) !important;
    color: var(--outlineButtonHoverTextColor) !important;
  }
  @media only screen and (max-width: 760px) {
    #discover-container {
      width: 100%;
      height: 100%;
    }
    #discover-modal {
      background-color: white;
    }
  }

  @media (max-width: 868px) {
    #appbar-root {
      display: block;
    }
    #appbar-container {
      padding: 0 16px;
    }
  }

  @media (min-width: 869px) {
    #appbar-container {
      width: 664px;
    }
  }

  @media (min-width: 1154px) {
    #appbar-container {
      width: 864px;
    }
  }

  @media (min-width: 1438px) {
    #appbar-container {
      width: 1120px;
    }
  }
`;

import { LitElement, html, PropertyValueMap } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { embedStyles } from "./vouchr-embed-css";
import { bootstrapStyles } from "@granite-elements/granite-lit-bootstrap/granite-lit-bootstrap.js";
import { TinyColor, isReadable } from "@ctrl/tinycolor";

@customElement("vouchr-embed")
export class VouchrEmbed extends LitElement {
  static override styles = [embedStyles, bootstrapStyles];

  @property()
  baseUrl: string | undefined;
  @property()
  jwt: string;
  @property()
  cid: string;
  @property()
  showDiscover: boolean;
  @property()
  showShareSheet: boolean;
  @property()
  claimUrl: string;
  @property()
  entryButtonUrl: string | undefined;
  @property()
  hidePrintable: Boolean;
  @property()
  hideSend: Boolean;
  @property()
  code: string;
  @property()
  amt: string;
  @property()
  cur: string;
  @property()
  recipient: string;
  @property()
  claimableAfter: string;
  @property()
  merchantExternalId: string;
  @property()
  sender: string;
  @property()
  authId: string;
  @property()
  view: string;
  @property()
  voucherId: string;
  @property()
  updateSummaryOnPending: Boolean;
  @property()
  confirmCancel: Boolean;
  @property()
  locale: string;
  @property()
  pin: string;
  @property()
  ref: string;
  @state()
  copyClicked: boolean;

  action: string;
  discoverUrl: string | undefined;
  shareTitle: string;
  printableUrl: string;

  constructor() {
    super();
    this.baseUrl = "";
    this.jwt = "";
    this.cid = "";
    this.action = "%2Fpersonalize";
    this.showDiscover = false;
    this.entryButtonUrl = "";
    this.discoverUrl = "";
    this.claimUrl = "";
    this.shareTitle = "I sent you an eCard!";
    this.showShareSheet = false;
    this.printableUrl = "";
    this.hidePrintable = false;
    this.hideSend = false;
    this.code = "";
    this.amt = "";
    this.cur = "";
    this.recipient = "";
    this.claimableAfter = "";
    this.merchantExternalId = "";
    this.sender = "";
    this.authId = "";
    this.view = "";
    this.voucherId = "";
    this.updateSummaryOnPending = false;
    this.confirmCancel = false;
    this.locale = "";
    this.pin = "";
    this.ref = "";
    this.copyClicked = false;
    window.addEventListener("message", this.receiveMessage, false);
  }

  getParams = () => {
    const params = [`action=${this.action}`];
    if (this.jwt) params.push(`tkn=${encodeURIComponent(this.jwt)}`);
    if (this.cid) params.push(`cid=${encodeURIComponent(this.cid)}`);
    if (this.code) params.push(`code=${encodeURIComponent(this.code)}`);
    if (this.amt) params.push(`amt=${encodeURIComponent(this.amt)}`);
    if (this.cur) params.push(`cur=${encodeURIComponent(this.cur)}`);
    if (this.recipient)
      params.push(`recipients=${encodeURIComponent(this.recipient)}`);
    if (this.claimableAfter)
      params.push(`claimableAfter=${encodeURIComponent(this.claimableAfter)}`);
    if (this.merchantExternalId)
      params.push(
        `merchantExternalId=${encodeURIComponent(this.merchantExternalId)}`
      );
    if (this.sender) params.push(`sender=${encodeURIComponent(this.sender)}`);
    if (this.authId) params.push(`authId=${encodeURIComponent(this.authId)}`);
    if (this.locale) params.push(`locale=${encodeURIComponent(this.locale)}`);
    if (this.pin) params.push(`pin=${encodeURIComponent(this.pin)}`);
    if (this.ref) params.push(`ref=${encodeURIComponent(this.ref)}`);
    return params.join("&");
  };

  override connectedCallback() {
    super.connectedCallback();
    this.entryButtonUrl = this.baseUrl
      ? `${this.baseUrl}#${this.getParams()}`
      : undefined;

    const style = document.createElement("style");
    style.innerHTML = ".vouchrModalOpen { overflow: hidden; }";
    document.getElementsByTagName("head")[0].appendChild(style);
  }

  protected override willUpdate(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (_changedProperties.has("baseUrl")) {
      this.action = "%2Fpersonalize";
      this.entryButtonUrl = this.baseUrl
        ? `${this.baseUrl}?ts=${Date.now()}#${this.getParams()}`
        : undefined;
    }

    if (_changedProperties.has("view") && _changedProperties.has("voucherId")) {
      if (this.view === "edit" && this.voucherId) {
        this.action = `%2Fvoucher%2F${this.voucherId}`;
        this.updateDiscoverUrl();
        this.showDiscover = true;
      } else if (this.view === "preview" && this.voucherId) {
        this.action = `%2Fpreview%2F${this.voucherId}`;
        this.updateDiscoverUrl();
        this.showDiscover = true;
      } else if (this.view === "summary" && this.voucherId) {
        this.action = `%2Fpersonalize%2Fsummary%2F${this.voucherId}`;
        this.updateEntryButtonUrl();
      } else {
        this.action = "";
        this.showDiscover = false;
      }
    }
  }

  override firstUpdated() {
    const primaryColor = getComputedStyle(
      this.renderRoot.querySelector("#root")!
    ).getPropertyValue("--primary")
      ? new TinyColor(
          getComputedStyle(
            this.renderRoot.querySelector("#root")!
          ).getPropertyValue("--primary")
        ).toHexString()
      : "#0066ff";
    const isButtonReadable = isReadable(primaryColor, "#FFFFFF");
    this.style.setProperty(
      "--solidButtonTextColor",
      isButtonReadable ? "white" : "black"
    );
    this.style.setProperty(
      "--outlineButtonHoverTextColor",
      isButtonReadable ? "white" : "black"
    );
  }

  updateEntryButtonUrl = () => {
    this.entryButtonUrl = this.baseUrl
      ? `${this.baseUrl}?ts=${Date.now()}#${this.getParams()}`
      : "";
  };

  updateDiscoverUrl = () => {
    this.discoverUrl = this.baseUrl
      ? `${this.baseUrl}#/?${this.getParams()}`
      : undefined;
  };

  receiveMessage = (event: any) => {
    if (event.data && event.data.event) {
      if (event.data.event === "PERSONALIZE") {
        this.action = event.data.actions.personalize;
        this.updateDiscoverUrl();
        this.showDiscover = true;
        return;
      } else if (
        event.data.event === "VOUCHER_PENDING" &&
        this.updateSummaryOnPending
      ) {
        const vouchrEvent = new CustomEvent("pending", {
          detail: event.data,
        });
        this.dispatchEvent(vouchrEvent);
        this.action = event.data.actions.summary;
        this.updateEntryButtonUrl();
        this.handleDiscoverClose();
        return;
      } else if (event.data.event === "VOUCHER_CREATED") {
        const vouchrEvent = new CustomEvent("created", {
          detail: event.data,
        });
        this.dispatchEvent(vouchrEvent);
        this.shareTitle = event.data.shareInfo.title;
        this.claimUrl = event.data.url;
        this.action = event.data.actions.summary;
        this.printableUrl = event.data.printableUrl;
        if (!this.updateSummaryOnPending) this.updateEntryButtonUrl();
        this.handleDiscoverClose();
        return;
      } else if (event.data.event === "EDIT") {
        this.action = event.data.actions.edit;
        this.updateDiscoverUrl();
        this.showDiscover = true;
        return;
      } else if (event.data.event === "VOUCHER_REMOVED") {
        const vouchrEvent = new CustomEvent("removed", {
          detail: event.data,
        });
        this.dispatchEvent(vouchrEvent);
        this.claimUrl = "";
        return;
      } else {
        return;
      }
    }
  };

  onUserCloseButtonClick = () => {
    if (this.confirmCancel) {
      if (!window.confirm("Are you sure you want to abandon this card?")) {
        return;
      }
    }
    this.handleDiscoverClose();
    const discoverClosedByUserEvent = new CustomEvent("cancelled", {
      detail: {
        voucherStatus: this.claimUrl ? "exists" : "null",
      },
    });
    this.dispatchEvent(discoverClosedByUserEvent);
  };

  handleDiscoverClose = () => {
    this.showDiscover = false;
  };

  handleSend = () => {
    this.showShareSheet = true;
    if (this.view === "edit" && this.voucherId) return;
    this.action = "%2Fpersonalize";
    this.updateEntryButtonUrl();
  };

  handleCopyUrl = () => {
    if (window.isSecureContext && navigator.clipboard) {
      this.copyClicked = true;
      navigator.clipboard.writeText(this.claimUrl);
      return;
    }
    const inputElement = document.createElement("input");

    document.body.appendChild(inputElement);

    inputElement.setAttribute("id", "clipboard-input");
    inputElement.setAttribute("value", this.claimUrl);
    inputElement.select();

    document.execCommand("copy");

    document.body.removeChild(inputElement);
    this.copyClicked = true;
  };

  handleSendEmail = () => {
    const subject = "Check out this eCard!";
    const message = `I sent you an eCard, \r\n\r\n${this.claimUrl}`;

    window.location.href = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
  };

  handleShareSheetClose = () => {
    const shareSheetClosedEvent = new CustomEvent("shareSheetClosed", {
      detail: "shareSheetClosed",
    });
    this.dispatchEvent(shareSheetClosedEvent);
    this.showShareSheet = false;
    this.copyClicked = false;
    if (!(this.view === "edit" && this.voucherId)) {
      this.claimUrl = "";
    }
  };

  handleShareButtonClick = () => {
    const shareData = {
      title: this.shareTitle,
      url: this.claimUrl,
    };
    (window.navigator as any).share(shareData);
  };
  handlePrint = () => {
    window.open(this.printableUrl);
  };

  renderShareButton = () => {
    const hasBuiltInShareMenu = (window.navigator as any).canShare;
    return hasBuiltInShareMenu
      ? html`<button
          type="button"
          class="btn btn-primary"
          id="share-button"
          @click=${this.handleShareButtonClick}
        >
          SHARE
        </button>`
      : null;
  };

  renderPrintButton = () => {
    return !this.hidePrintable
      ? html`<button
          type="button"
          class="btn btn-outline-primary"
          id="print-button"
          @click=${this.handlePrint}
        >
          PRINT
        </button>`
      : null;
  };

  renderDiscover = () => {
    if (this.showDiscover || this.showShareSheet) {
      document.body.classList.add("vouchrModalOpen");
    } else {
      document.body.classList.remove("vouchrModalOpen");
    }
    return this.showDiscover
      ? html`
          <div id="discover-modal">
            <div id="discover-container">
              <div id="appbar-root">
                <div id="appbar-container">
                  <div id="close-button-container">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      id="close-button"
                      @click=${this.onUserCloseButtonClick}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div id="logo-container">
                    <div id="logo"></div>
                  </div>
                  <div id="appbar-placeholder"></div>
                </div>
              </div>
              ${this.discoverUrl
                ? html`<iframe
                    id="discover"
                    src=${this.discoverUrl}
                    allow="clipboard-write"
                  ></iframe>`
                : ""}
            </div>
          </div>
        `
      : this.showShareSheet
      ? html`
          <div id="share-sheet-modal">
            <div id="share-sheet">
              <div id="share-sheet-header">
                <div id="share-sheet-header-title">Send Your eCard!</div>
                <button
                  id="share-sheet-close-btn"
                  type="button"
                  class="close"
                  aria-label="Close"
                  id="close-button"
                  @click=${this.handleShareSheetClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div id="share-btn-container">
                ${this.renderShareButton()}
                <button
                  type="button"
                  class=${(window.navigator as any).canShare
                    ? "btn btn-outline-primary"
                    : "btn btn-primary"}
                  id="copy-button"
                  @click=${this.handleCopyUrl}
                >
                  ${this.copyClicked ? "COPIED" : "COPY LINK"}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  id="email-button"
                  @click=${this.handleSendEmail}
                >
                  SEND EMAIL
                </button>
                ${this.renderPrintButton()}
              </div>
            </div>
          </div>
        `
      : null;
  };

  renderSendButton = () => {
    return this.claimUrl &&
      !this.showShareSheet &&
      !this.showDiscover &&
      !this.hideSend &&
      this.action !== "%2Fpersonalize"
      ? html`
          <button
            type="button"
            class="btn btn-primary"
            id="send-button"
            @click=${this.handleSend}
          >
            SEND
          </button>
        `
      : null;
  };

  renderEntryButton = () => {
    return (this.view === "edit" && this.voucherId && !this.claimUrl) ||
      !this.entryButtonUrl
      ? ``
      : html`<iframe
          scrolling="no"
          id="entry-button"
          src=${this.entryButtonUrl}
        ></iframe>`;
  };

  override render() {
    return html`
      <div id="root">
        ${this.renderEntryButton()} ${this.renderDiscover()}
        ${this.renderSendButton()}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "vouchr-embed": VouchrEmbed;
  }
}
